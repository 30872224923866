<template>
  <div class="card">
    <div class="card-header pb-0">
    </div>
    <div class="card-body px-0 pb-2" style="height: 340px;">
     
    <TreeTable :value="billingData" tableStyle="min-width: 60rem">
    <Column field="field1" header="Bill-No" expander></Column>
    <Column field="field2" header="Customer"></Column>
    <Column field="field3" header="Project Name"></Column>
    <Column field="field4" header="Payment Method"></Column>
    <Column field="field5" header="Amount"></Column>
    <Column field="field6" header="Date"></Column>
    <Column field="field7" header="Payment Status"></Column>
    
    
    <Column field="field8" header="Actions">
        <template #body="slotProps">
          <template v-if="isParent(slotProps)">
            <Button 
              label="Pay Bill" 
              icon="pi pi-money-bill" 
              @click="openModal(slotProps)" 
              class="custom-button"
            ></Button>
          </template>
        </template>
      </Column>
    </TreeTable>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { toRaw } from 'vue';
// import authAxios from '../../authAxios.js';
import authAxios from '../authAxios.js';
import TreeTable from 'primevue/treetable';
import Button from 'primevue/button';
import Column from 'primevue/column';
export default {
  name: "BillingCard",
  components: {
    TreeTable,
    Column,
    Button,
  },
  data() {
    return {
      billingData: []
    };
  },
  methods: { 
    isParent(node) {
      return toRaw(node.node).children && toRaw(node.node).children.length > 0;//node.parent ? true : false; // Check if the node has a parent
      // return node.children && node.children.length > 0;
    },
    async listBills() {
      try {
        const response = await authAxios.post("client_get_billing_data");
        this.billingData = this.transformBills(response.data.payload); // Transform the response data
      } catch (error) {
        console.error(error);
      }
    },
    transformBills(apiResponse) {
      return apiResponse.map((bill, index) => {

       // Transform transactions into children
    const children = bill.transaction.map((transaction, childIndex) => {
      // Log each transaction as it is mapped
      console.log('Mapping transaction:', transaction);

      return {
        key: `${index}-${childIndex}`, // Unique key for each transaction
        data: {
      
          field1: `TRX-${transaction.transaction_id || "N/A"}`,
          field2: `Amount: ${transaction.amount || "N/A"}`,
          field7: `${transaction.payment_status || "N/A"}`,
          field6: `Date: ${transaction.updated_at || "N/A"}`
        },
      };
    });

    return {
      key: index.toString(), // Unique key for each bill
      data: {
        field1: bill.transaction_id || "N/A",
        field2: bill.client?.fullname || "N/A",
        field3: bill.project?.name || "N/A",
        field4: bill.payment_method || "N/A",
        field5: `UGX: ${bill.amount}`,
        field6: bill.billing_date,
        field7: bill.payment_status
      },
      children, 
    };
      });
    },
    isButtonDisabled(rowData) {
      const rawData = toRaw(data); 
 
      return toRaw(rawData.node).data.paymentStatus=== "fully_paid"; // Disable if fully paid
    },
    ...mapActions("flutterwave", ["openModal", "closeModal"]),
    payBill(data) {
    
      const rawData = toRaw(data); // Convert to a raw object

    // console.log('Payment Successful', toRaw(rawData.node)); // 
    // console.log('Payment Successful', toRaw(rawData.node).data.transaction_id); // 
      // Your payment logic here
    },
  },
  created() {
    this.listBills(); // Fetch orders when the component is created
  },
};
</script>
