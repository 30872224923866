
<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <!-- <navbar isBtn="bg-gradient-light" /> -->
      </div>
    </div>
  </div>
  <main class="main-content mt-0">
    <div class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg" style="
        background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg&quot;);
        background-position: top;
      ">
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5 text-center mx-auto">
            <h1 class="text-white mb-2 mt-5">Welcome!</h1>
            <!-- <p class="text-lead text-white">
              Use these awesome forms to login or create new account in your
              project for free.
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>Register with us</h5>
            </div>
            <div class="row px-xl-5 px-sm-4 px-3">
            
            </div>
            <div class="card-body">
              <form role="form" @submit.prevent="handleRegister">
                <label class="text-warning">Full name</label>

                <input type="text" v-model="fullnames" class="form-control mb-3 border-warning" placeholder="Full names" required="" />
                <label class="text-warning">Email</label>

                <input type="email" v-model="email" class="form-control mb-3 border-warning" placeholder="Email" required="" />
                <label class="text-warning">Phone</label>

                <input type="tel" v-model="tel" class="form-control mb-3 border-warning" placeholder="0700000000" required="" />
                <label class="text-warning">Password</label>
                <input type="password" v-model="password" class="form-control mb-3 border-warning" placeholder="password"
                  required="" />
                <label class="text-warning">Branch</label>
                <!-- branches -->
                <!-- Display loading spinner if data is still being fetched -->
                <div class="" v-if="isLoading">Loading branches...</div>

                <!-- Display error message if there's an error -->
                <div v-if="error">{{ error }}</div>

                <!-- Dropdown to select a branch -->
                <select class="form-control mb-3 border-warning" v-model="branch_id">
                  <option value="" disabled>Select branch</option>
                  <!-- Loop through branches and create an option for each -->
                  
                  <option class="" v-for="branch in branches" :key="branch.id" :value="branch.id">{{ branch.branch_name }}</option>
                </select>


                <argon-checkbox checked>
                  <label class="form-check-label text-warning" for="flexCheckDefault">
                    I agree the
                    <a href="javascript:;" class="text-dark font-weight-bolder">Terms & Conditions</a>
                  </label>
                </argon-checkbox>
                <div class="text-center">
                  <argon-button fullWidth color="dark" variant="gradient" class="my-4 mb-2"
                    :disabled="isButtonDisabled">
                    <span v-if="logIn" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>

                    <span v-if="logIn" class="visually">Registering...</span>

                    <span v-if="!logIn" class="visually"> Sign up</span>
                  </argon-button>
                </div>
                <p class="text-sm mt-3 mb-0 text-warning">
                  Already have an account?
                  <router-link to="/signin" class="text-dark font-weight-bolder">Login</router-link>

                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>
<script>
import { onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import axiosInstance from '../authAxios.js';

import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  components: {
    AppFooter,
    ArgonInput,
    ArgonCheckbox,
    ArgonButton,
  },
  data() {
    return {
      fullnames: "",
      email: "",
      password: "",
      tel: "",
      branch_id: "",
      branches: [],
      logIn: false,
      isButtonDisabled: false,
      isLoading: true,
      error: null,
      data: null,
    };
  },
  created() {
    this.store = useStore();
    this.router = useRouter();
    this.toast = useToast();
    this.body = document.getElementsByTagName("body")[0];

    this.store.state.hideConfigButton = true;
    this.store.state.showNavbar = false;
    this.store.state.showSidenav = false;
    this.store.state.showFooter = false;
    this.body.classList.remove("bg-gray-100");

    this.fetchBranches();
  },
  beforeUnmount() {
    this.store.state.hideConfigButton = false;
    this.store.state.showNavbar = true;
    this.store.state.showSidenav = true;
    this.store.state.showFooter = true;
    this.body.classList.add("bg-gray-100");
  },
  methods: {
    async fetchBranches() {
      try {
        const response = await axiosInstance.post("general/all_branches");
        this.branches = response.data.payload;
      } catch (err) {
        this.error = "Error fetching branches";
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    async handleRegister() {
      try {
        this.logIn = true;
        this.isButtonDisabled = true;

        const response = await axiosInstance.post("client_register", {
          fullname: this.fullnames,
          email: this.email,
          tel: this.tel,
          password: this.password,
          branch_id: 1,
        });

        this.data = response.data;
        console.log("Response Data:", this.data);

        const status = this.data.status;

        if (status === "SUCCESS") {
          this.toast.open({
            message: this.data.payload,
            type: "default",
            position: "top-right",
            timeout: 2000,
          });
          this.$router.push("/signin");
          // setTimeout(() => {
          //   this.router.push("/signin");
          // }, 3000);
        } else {
          this.toast.open({
            message: this.data.payload,
            type: "warning",
            position: "top-right",
            timeout: 2000,
          });
          this.logIn = false;
          this.isButtonDisabled = false;
        }
      } catch (error) {
        this.logIn = false;
        this.isButtonDisabled = false;
        this.toast.open({
          message: error.message || "There was an error!",
          type: "default",
          position: "top-right",
          timeout: 2000,
        });
        console.error("Error:", error);
      }
    },
  },
};
</script>

