<template>
  <div class="fixed-plugin">
    <a class="px-3 py-1 fixed-plugin-button text-dark position-fixed d-flex flex-column align-items-center"
      style="background-color: #fbcf33;" @click="toggle">
      <i class="cart-number -mb-4">{{ cartTotalItems }}</i>
      <i class="py-2 fa fa-cart-plus"></i>
    </a>

    <div class="shadow-lg card blur ">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="float-start">
          <h5 class="mt-3 mb-0">Shopping Cart</h5>
          <p>See our dashboard options.</p>
        </div>
        <div class="mt-4 float-end" @click="toggle">
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="fa fa-close"></i>
          </button>
        </div>
        <!-- End Toggle Button -->
      </div>
      <hr class="my-1 horizontal dark" />
      <div class="mt-4">
        <!-- {{ cartItems }} -->
        <ul class="list-group border-none">
          <li class="list-group-item d-flex justify-content-between align-items-center no-border shadow-lg mb-2"
            v-for="(item, index) in cartItems" :key="index">
            <div class="d-flex align-items-center">
              <img :src="imageUrl || placeholderUrl" alt="Product Image" class="img-fluid me-2 rounded"
                style="width: 60px; height: 60px;" />
              <div>
                <h6 class="text-sm">{{ item.itemstocked }}</h6>
                <div class="d-flex align-items-center">
                  <i class="fas fa-minus-circle text-dark me-2" @click="decreaseQuantity(item.id)"
                    style="cursor: pointer;"></i>
                  <span class="text-sm mb-0 ">Qty: {{ item.quantity }} x {{ item.saleprice }}</span>
                  <i class="fas fa-plus-circle text-success ms-2" @click="increaseQuantity(item.id)"
                    style="cursor: pointer;"></i>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <span class="badge text-dark me-5">{{ item.saleprice * item.quantity }}</span>

              <i class="fas fa-trash-alt text-danger me-1" @click="removeFromCart(item.id)"
                style="cursor: pointer;"></i>
            </div>

          </li>


        </ul>


        <div class="form-group shadow-lg mt-2 px-2 rounded-lg">
          <hr class="my-2 horizontal dark" />
          <div>
            <a class="btn btn-block btn-dark w-100 text-white justify-content-between">Total:<i> UGX: {{ cartTotalPrice
                }}</i></a>

            <hr class="my-1 horizontal dark" />
            <div>

              <div class="payment-container" style="width: 320px; margin: 10px auto;">
                <h5 class="text-center">Select Payment Method</h5>
                <div class="d-flex justify-content-between">
                  <div class="grid-item p-1" v-for="method in paymentMethods" :key="method.name">
                    <button class="btn btn-block bg-dark text-white w-100" @click="selectPaymentMethod(method.type)">

                      <i :class="method.icon" style="font-size: 12px;" aria-hidden="true"></i>
                    </button>
                    <span class="method-name text-sm mt-1 text-center text-dark"><i>{{ method.name }}</i></span>
                  </div>
                </div>

              </div>
              <hr class="my-1 horizontal dark" />
              <h5 class="mb-1 text-sm ">{{ selectedMethod }} Information</h5>
              <form @submit.prevent="submitForm" v-if="selectedMethod === 'Card'">
                <div class="mb-3">
                  <label for="cardName" class="form-label">Cardholder Name</label>
                  <input type="text" class="form-control" id="cardName" v-model="cardName" placeholder="John Doe"
                    :required="selectedMethod === 'Card'">

                </div>
                <div class="mb-3">
                  <label for="cardNumber" class="form-label text-sm">Card Number</label>
                  <InputMask id="cardNumber" v-model="cardNumber" mask="9999 9999 9999 9999"
                    placeholder="1234 5678 9012 3456" fluid :required="selectedMethod === 'Card'" />
                </div>
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="expiryDate" class="form-label text-sm">Expiry Date</label>
                    <InputMask id="expiryDate" v-model="expiryDate" mask="99/99" placeholder="MM/YY" fluid :required="selectedMethod === 'Card'"/>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="cvv" class="form-label text-sm">CVV</label>
                    <InputMask id="cvv" v-model="cvv" mask="999" placeholder="123" fluid :required="selectedMethod === 'Card'"/>
                  </div>
                </div>

              </form>

              <div v-if="selectedMethod === 'Mobile'" class="mb-3 ">
                <div class="flex-auto">

                  <label for="payment_type">Country</label>
                  <select name="payment_type" class="form-control" v-model="mobileNetwork" required>
                    <option value="mobile_money_uganda">UGANDA</option>
                    <option value="mobile_money_rwanda">RWANDA</option>
                    <option value="mobile_money_zambia">ZAMBIA</option>
                    <option value="mobile_money_ghana">GHANA</option>
                    <option value="mobile_money_franco">FRANCO</option>
                  </select>
                </div>
                <div class="flex-auto">
                  <label for="phone" class="font-bold block mb-2">Phone</label>
                  <InputMask id="phone" v-model="mobileNumber" mask="+(999) 999-999999" placeholder="+256 700 000 000"
                    fluid :required="selectedMethod === 'Mobile'"/>
                </div>
              </div>
              <div v-if="selectedMethod === 'Bank'" class="flex-auto">
                <div class="mb-3">
                  <label for="account_bank" class="form-label">Bank </label>
                  <input type="text" class="form-control" id="account_bank" v-model="account_bank" placeholder="bank"
                  :required="selectedMethod === 'Bank'">
                </div>
                <div class="mb-3">
                  <label for="account_number" class="form-label text-sm">Account Number</label>
                  <input type="number" class="form-control" id="account_bank_no" v-model="account_bank_no"
                    placeholder="0690000034" min="0" max="10" :required="selectedMethod === 'Bank'" >
                </div>
              </div>
              <div v-if="selectedMethod === 'Cash'" class="flex-auto">
                <div class="mb-3">
                  <label for="account_bank" class="form-label">Cash </label>
                  {{ cartTotalPrice }}
                </div>

              </div>


            </div>

          </div>
          <a class="btn btn-block btn-success w-100 text-white justify-content-between" @click="Checkout">Proceed to
            Checkout</a>
           
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import SelectButton from 'primevue/selectbutton';
import DataTable from 'primevue/datatable';
import InputMask from 'primevue/inputmask';
import Column from 'primevue/column';
// import authAxios from '../authAxios.js';
import authAxios from "../authAxios";
export default {
  name: "configurator",
  props: ["toggle"],
  components: {
    DataTable,
    Column,
    InputMask,
    SelectButton
  },
  data() {
    return {

      selectedMethod: null,
      paymentMethods: [
        { name: 'Credit Card', icon: 'fas fa-solid fa-credit-card', type: 'Card' },
        { name: 'Mobile-M', icon: 'fas fa-mobile', type: 'Mobile' },
        { name: 'Bank', icon: 'fas fa-solid fa-money-bill-wave', type: 'Bank' },
        { name: 'Cash ', icon: 'fas fa-solid fa-cash-register', type: 'Cash' },
      ],
      fixedKey: "",
      imageUrl: '',
      // Placeholder image URL
      placeholderUrl: 'https://placehold.jp/FFDAB9/ffffff/60x60.png?text=Yolum%20ltd',
      account_bank: '',
      account_bank_no: '',
      mobileNumber: '',
      payment_type: '',
      selectedMethod: '',
      cvv: '',
      expiryDate: '',
      cardNumber: '',
      cardName: '',
    };

  },
  methods: {
    async Checkout() {
  
      let trx_details = {
        account_bank: this.account_bank,
        account_bank_no: this.account_bank_no,
        mobileNumber: this.mobileNumber,
        payment_type: this.payment_type,
        selectedMethod: this.selectedMethod,
        cvv: this.cvv,
        expiryDate: this.expiryDate,
        cardNumber: this.cardNumber,
        cardName: this.cardName
      };
   
      await authAxios.post('client_add_order', {
        payment_method: this.selectedMethod,
        total_amount: this.cartTotalPrice,
        items: this.cartItems.slice(),
        trx_details:trx_details
      }).then((res) => {
        // console.log('RESPONSE',res);
        if (res.data.status === 'success') {
      
          this.openCheckout(res.data.meta.authorization.redirect);
      
        }
      })
      // })
    },
    validateAccountBank() {
      this.account_bank = this.account_bank.replace(/\D/g, '').slice(0, 10);
    },
    selectPaymentMethod(method) {
      this.selectedMethod = method;
    },
    ...mapMutations(["navbarMinimize", "sidebarType", "navbarFixed"]),
    // Map cart module mutations
    ...mapActions(["toggleSidebarColor"]),
    ...mapActions('cart', ['removeFromCart', 'clearCart', 'increaseQuantity', 'decreaseQuantity']),
    ...mapActions("flutterwave", ["openCheckout", "closeModal"]),

    sidebarColor(color = "success") {
      document.querySelector("#sidenav-main").setAttribute("data-color", color);
      this.$store.state.mcolor = `card-background-mask-${color}`;
    },

    sidebarType(type) {
      this.toggleSidebarColor(type);
    },

    setNavbarFixed() {
      if (this.$route.name !== "Profile") {
        this.$store.state.isNavFixed = !this.$store.state.isNavFixed;
      }
    },

    sidenavTypeOnResize() {
      let transparent = document.querySelector("#btn-transparent");
      let white = document.querySelector("#btn-white");
      // if (window.innerWidth < 1200) {
      //   transparent.classList.add("disabled");
      //   white.classList.add("disabled");
      // } else {
      //   transparent.classList.remove("disabled");
      //   white.classList.remove("disabled");
      // }
    },
  },
  computed: {
    ...mapGetters('cart', ['cartItems', 'cartTotalPrice', 'cartTotalItems']),
    ifTransparent() {
      return this.$store.state.isTransparent;
    },
    sidenavResponsive() {
      return this.sidenavTypeOnResize;
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
    // Deactivate sidenav type buttons on resize and small screens
    window.addEventListener("resize", this.sidenavTypeOnResize);
    window.addEventListener("load", this.sidenavTypeOnResize);
  },
};
</script>
<style scoped>
.fixed-plugin-button {
  /* General styles for the button */
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  cursor: pointer;
}

.fixed-plugin-button i {
  font-size: 1.5rem;
  /* Adjust the size of the cart icon */
}

.cart-number {
  margin-top: -22px;
  /* Move the "0" upwards */
  margin-right: -25px;
  font-size: 1rem;
  /* Adjust the size of the number "0" */
  font-weight: lighter;
  font-style: normal;
  font-family: monospace;
  text-align: center;
  background-color: rgb(230, 169, 14);
  border-radius: 50%;
  padding: 5px;
  line-height: 1;
  color: #333;
  /* Color for the "0" */
}

.fa-cart-plus {
  font-size: 2rem;
  /* Size of the cart icon */
}

.border-none {
  border: none !important;
}

.no-border {
  border: none !important;
  box-shadow: none;
}
</style>