/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import ShopGoods from "../views/ShopGoods.vue";
import clientProject from "../views/clientProject.vue";
import Billing from "../views/Billing.vue";
import OrderClinet from "../views/OrderClinet.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";

const checkUserType = (to, from, next) => { 
  const user = JSON.parse(localStorage.getItem('user'));

  console.log(user, 'user danda')
  if (user.level == 'CLIENT') {
   
    next();
 
  } else {

    console.log('Not ok');
    next('/signin');
  }
}

const routes = [
  {
    path: "/",
    name: "/",
    // redirect: "/dashboard-default",
    redirect: "/signin",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: checkUserType,
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
    beforeEnter: checkUserType,
  },
  {
    path: "/myproject",
    name: "my-project",
    component: clientProject,
    beforeEnter: checkUserType,
  },
  {
    path: "/shop-goods",
    name: "shop-goods",
    component: ShopGoods,
    beforeEnter: checkUserType,
  },
  
  {
    path: "/transactions",
    name: "transactions",
    component: Tables,
    beforeEnter: checkUserType,
  },
  {
    path: "/services",
    name: "services",
    component: Tables,
    beforeEnter: checkUserType,
  },
  {
    path: "/teams",
    name: "teams",
    component: Tables,
    beforeEnter: checkUserType,
  },
  
  {
    path: "/emails",
    name: "emails",
    component: Tables,
    beforeEnter: checkUserType,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
    beforeEnter: checkUserType,
  },
  {
    path: "/myorders",
    name: "myorders",
    component: OrderClinet,
    beforeEnter: checkUserType,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    beforeEnter: checkUserType,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/register",
    name: "register",
    component: Signup,
  },
];

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});



export default router;
