import { toRaw } from "vue";

// store/cart.js
export default {
  namespaced: true,
  state: {
    cart: []
  },
  mutations: {
    ADD_TO_CART(state, product) {

      let prod = toRaw(product);
      console.log('Product being added:', prod);

      // Check if product has an 'id' property
      if (!prod.id) {
        console.error('Product does not have an ID:', prod);
        return;
      }

      // Check if the product is already in the cart
      const item = state.cart.find(item => item.id === prod.id);

      console.log('Item found in cart:', item);

      if (item) {
       
        item.quantity += 1;
        console.log('Updated quantity for item in cart:', item);
      } else {
        // Product not in cart, add to cart
        console.log('Adding new product to cart:', prod);
        state.cart.push({ ...prod, quantity: 1 });
      }
    },
    REMOVE_FROM_CART(state, productId) {
      state.cart = state.cart.filter(item => item.id !== productId);
    },
    UPDATE_CART_ITEM(state, { productId, quantity }) {
      const item = state.cart.find(item => item.id === productId);
      if (item) {
        item.quantity = quantity;
      }
    },
    CLEAR_CART(state) {
      state.cart = [];
    },
    INCREASE_QUANTITY(state, productId) {
      const product = state.cart.find(item => item.id === productId);
      if (product) {
        product.quantity++;
      }
    },
    DECREASE_QUANTITY(state, productId) {
      const product = state.cart.find(item => item.id === productId);
      if (product && product.quantity > 1) {
        product.quantity--;
      }
    }
  },
  actions: {
    addToCart({ commit }, product) {
      console.log('addToCart', product)
      commit('ADD_TO_CART', product);
    },
    removeFromCart({ commit }, productId) {
      console.log('REMOVE_FROM_CART', productId)
      commit('REMOVE_FROM_CART', productId);
    },
    updateCartItem({ commit }, payload) {
      commit('UPDATE_CART_ITEM', payload);
    },
    increaseQuantity({ commit }, productId) {
      commit('INCREASE_QUANTITY', productId);
    },
    decreaseQuantity({ commit }, productId) {
      commit('DECREASE_QUANTITY', productId);
    },
    clearCart({ commit }) {
      commit('CLEAR_CART');
    }
  },
  getters: {
    cartItems: state => state.cart,

    cartQuantity: state => state.cart.length,
    cartTotalPrice: state => {
      return state.cart.reduce((total, item) => {
        return total + item.saleprice * item.quantity;
      }, 0);
    },
    cartTotalItems: state => {
      return state.cart.reduce((total, item) => total + item.quantity, 0);
    }
  }
};
