<!-- ModalComponent.vue -->
<template>
    <div v-if="isCheckoutModalOpen" class="modal-overlay" @click.self="close">
      <div class="modal-content">
        <button class="close-btn" @click="close">✕</button>
        <h2 class="modal-title">Secure Checkout</h2>
        <p class="modal-description">
          Please complete the OTP verification to proceed with the payment.
        </p>
        <div v-if="iframeError" class="iframe-error">
          <p>Unable to load the payment page. <a :href="modalPageUrl" target="_blank">Click here</a> to open in a new tab.</p>
        </div>
        <iframe
          v-else
          :src="modalPageUrl"
          frameborder="0"
          class="otp-iframe"
          @load="onIframeLoad"
          @error="onIframeError"
        ></iframe>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isCheckoutModalOpen: {
        type: Boolean,
        required: true,
      },
      modalPageUrl: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        iframeError: false,
      };
    },
    methods: {
      close() {
        this.$emit("close");
      },
      onIframeLoad() {
        this.iframeError = false; // Reset error state on successful load
      },
      onIframeError() {
        this.iframeError = true; // Set error state on load failure
      },
    },
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #ffffff;
    padding: 30px 20px;
    border-radius: 12px;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    text-align: center;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .modal-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
    font-weight: bold;
  }
  
  .modal-description {
    font-size: 1rem;
    margin-bottom: 20px;
    color: #666;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #333;
    transition: color 0.3s ease;
  }
  
  .close-btn:hover {
    color: #ff4b4b;
  }
  
  .otp-iframe {
    width: 100%;
    height: 450px;
    border-radius: 8px;
    border: 1px solid #ddd;
  }
  
  .iframe-error {
    font-size: 1rem;
    color: #ff4b4b;
  }
  
  .iframe-error a {
    color: #007bff;
    text-decoration: none;
  }
  
  .iframe-error a:hover {
    text-decoration: underline;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  </style>
  