<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col-lg-6 col-7">
          <h6>Order list</h6>
        </div>
      </div>
    </div>
    <div class="card-body px-0 pb-2" style="height: 340px;">
      <TreeTable :value="orders" tableStyle="min-width: 60rem">
        <Column field="orderNumber" header="Order Number" expander></Column>
        <Column field="date" header="Date"></Column>
        <Column field="total" header="Total"></Column>
        <Column field="status" header="Status"></Column>
      </TreeTable>
    </div>
  </div>
</template>

<script>
import authAxios from '../authAxios.js';
import TreeTable from 'primevue/treetable';
import Column from 'primevue/column';

export default {
  name: "orderPage",
  components: {
    TreeTable,
    Column
  },
  data() {
    return {
      orders: []
    };
  },
  methods: { 
    async listOrders() {
      try {
        const response = await authAxios.post("client_list_order");
        console.log('ORDER', response.data);
        this.orders = this.transformOrders(response.data.payload);
      } catch (error) {
        console.error(error);
      }
    },
    transformOrders(apiResponse) {
      return apiResponse.map((order, index) => {
        const children = order.items.map((item, childIndex) => ({
          key: `${index}-${childIndex}`,
          data: {
            orderNumber: `Stock ID ${item.stock_id}`, // or any other identifier
            date: `Unit Price: ${item.price}`,
            total: `Subtotal: ${item.total}`,
            status: '',
          },
        }));
  
        return {
          key: index.toString(),
          data: {
            orderNumber: order.order_number,
            customer: order.client ? `${order.client.fullname}` : 'N/A',
            date: order.order_date,
            total: `UGX: ${order.total_amount}`,
            status: order.status,
          },
          children,
        };
      });
    },
  },
  created() {
    this.listOrders(); // Fetch orders when the component is created
  },
};
</script>
