<template>
    <div class="card mb-4">
        <div class="card-header pb-0">
            <h6>Stock table</h6>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">



                <table
                    class=" table align-items-center w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">

                    <thead class="text-xs text-gray-900 uppercase dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                Product name
                            </th>



                            <th scope="col" class="px-6 py-3">
                                SALE PRICE
                            </th>

                            <th scope="col" class="px-6 py-3">
                                ACTION
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr class="bg-white dark:bg-gray-800" v-for="(items, index) in Stocks" :key="index">
                    
                            <td class="px-6 py-4">
                                {{ items.itemstocked }}
                            </td>
                            <td class="px-6 py-4">
                                {{ items.saleprice }}
                            </td>
                            
                         
                            <td class="px-6 py-4">
                                <i class="pi pi-cart-plus" @click="addToCart(items)" style="font-size: 2rem"></i>

                            </td>
                      
                       
                        </tr>

                    </tbody>
                </table>


            </div>
        </div>
    </div>
</template>

<script>


import authAxios from '../authAxios.js';
import 'primeicons/primeicons.css';
import { mapActions } from 'vuex';
import moment from 'moment'

export default {
    name: "sale-table",
    data() {
        return {
            Stocks: null,
        };
    },
    created() {
        this.listStocks();
    },
    methods: {
        ...mapActions('cart', ['addToCart']),
        moment,
        async listStocks() {
            authAxios.post("list_stock").then((resp) => {
                this.Stocks = resp.data.payload
                console.log(this.Stocks, "stockList")
            }).catch((err) => {
                console.log(err, "stockList")
            })

        }
    },

};
</script>