<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import Sidenav from "./examples/Sidenav";
import Configurator from "@/examples/Configurator.vue";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import FlutterOtp from "@/examples/flutterOtp.vue"; 

const store = useStore();

// Global Vuex states
const isNavFixed = computed(() => store.state.isNavFixed);
const darkMode = computed(() => store.state.darkMode);
const isAbsolute = computed(() => store.state.isAbsolute);
const showSidenav = computed(() => store.state.showSidenav);
const layout = computed(() => store.state.layout);
const showNavbar = computed(() => store.state.showNavbar);
const showFooter = computed(() => store.state.showFooter);
const showConfig = computed(() => store.state.showConfig);
const hideConfigButton = computed(() => store.state.hideConfigButton);

// Flutterwave module states
const isModalOpen = computed(() => store.state.flutterwave.isModalOpen);
const modalPageUrl = computed(() => store.state.flutterwave.modalPageUrl);
const billPayload = computed(() => store.state.flutterwave.billPayload);
const isCheckoutModalOpen = computed(
  () => store.state.flutterwave.isCheckoutModalOpen
);

// Actions from the Flutterwave module
const openModal = () => store.dispatch("flutterwave/openModal");
const closeModal = () => store.dispatch("flutterwave/closeModal");
const openCheckout = () => store.dispatch("flutterwave/openCheckout");

// Commit function for toggling configurator
const toggleConfigurator = () => store.commit("toggleConfigurator");

// Navbar classes
const navClasses = computed(() => {
  return {
    "position-sticky bg-white left-auto top-2 z-index-sticky":
      isNavFixed.value && !darkMode.value,
    "position-sticky bg-default left-auto top-2 z-index-sticky":
      isNavFixed.value && darkMode.value,
    "position-absolute px-4 mx-0 w-100 z-index-2": isAbsolute.value,
    "px-0 mx-4": !isAbsolute.value,
  };
});
</script>

<template>
  <div
    v-show="layout === 'landing'"
    class="landing-bg h-100 bg-gradient-warning position-fixed w-100"
  ></div>

  <sidenav v-if="showSidenav" />

  <main
    class="main-content position-relative max-height-vh-100 h-100 border-radius-lg"
  >
    <!-- Navbar -->
    <navbar :class="[navClasses]" v-if="showNavbar" />

    <!-- Router View -->
    <router-view />

    <!-- Footer -->
    <app-footer v-show="showFooter" />

    <!-- Configurator -->
    <configurator
      :toggle="toggleConfigurator"
      :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
    />
    <FlutterOtp
      :isCheckoutModalOpen="isCheckoutModalOpen"
      :modalPageUrl="modalPageUrl"
      @close="closeModal"
    />
  </main>
</template>
